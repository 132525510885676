/**
 * Will emit event with provided data, default data is empty `Object`
 * @param {string} event Defined event enum
 * @param {object} data Data to be passed when emitted event
 */
// eslint-disable-next-line
 export const emit = (event: string, data: object = {}): void => {
  window.dispatchEvent(new CustomEvent(event, { detail: data }));
};
/**
 * Will listen to specific event
 * @param {string} event Defined event enum
 * @param {Function} callback Callback function to be called if event emitted
 */
export const listen = (event: string, callback: (data: any) => void): void => {
  window.addEventListener(event, (e: Event) => {
    callback((e as CustomEvent).detail);
  });
};