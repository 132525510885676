/**
 * API Plugin
 */

import axios, { Method, AxiosRequestConfig } from "axios";
import { APIResponse } from "./interfaces";

/**
 * Initialize axios
 */
const token = (document.querySelector("[name=csrf-token]") as HTMLMetaElement)
  ?.content;

const httpWrapper = async (
  method: Method,
  endpoint: string,
  data: Record<string, unknown> = {}
): Promise<APIResponse> => {
  const options: AxiosRequestConfig = {
    method: method,
    url: `/api/v1${endpoint}`,
    headers: {
      "X-CSRF-TOKEN": token,
      "Content-Type": "application/json",
    },
  };

  if (method.toLocaleLowerCase() === "get") {
    options.params = data;
  } else {
    options.data = data;
  }

  try {
    const response = await axios(options);
    return { result: response.data, error: null };
  } catch (error) {
    console.log(error);

    if ("response" in error) {
      return { result: error.response, error: error };
    }

    return { result: "error", error: error };
  }
};

// Set module
export default {
  users: {
    /**
     * User Sign Up
     */
    async signUp(
      first_name: string,
      last_name: string,
      email: string,
      phone: string,
      password: string,
      password_confirmation: string
    ): Promise<APIResponse> {
      return httpWrapper("post", "/users/sign_up", {
        first_name: first_name,
        last_name: last_name,
        email: email,
        phone: phone,
        password: password,
        password_confirmation: password_confirmation,
      });
    },
    /**
     * Current user data
     */
    async me(): Promise<APIResponse> {
      return httpWrapper("get", "/users/me");
    },
  },
};
