/**
 * Popup vuex module
 */
import { Module, ActionTree, MutationTree } from "vuex";
import {
  APIResponse,
  CurrentUserAPIResponse,
  ApplicationsState,
  RootState,
} from "@/interfaces";
import api from "@/internal-api";
import { APPLICATIONS_SET_HMAC, FETCH_APPLICATIONS } from "@/store-types";

/**
 * Module state
 */
const state: ApplicationsState = {
  hmacAuthApplications: [],
};

/**
 * Module mutation
 */
const mutations: MutationTree<ApplicationsState> = {
  [APPLICATIONS_SET_HMAC](state: ApplicationsState, hmacAuthApplications: []) {
    state.hmacAuthApplications = hmacAuthApplications;
  },
};

/**
 * Module action
 */
const actions: ActionTree<ApplicationsState, RootState> = {
  /**
   * Fetch current applications data
   */
  async [FETCH_APPLICATIONS]({ commit }) {
    const { result, error }: CurrentUserAPIResponse =
      await api.applications.getHmacAuthApplications();
    if (error) {
      throw error;
    }
    commit(APPLICATIONS_SET_HMAC, result.data);
  },
};

export const applications: Module<ApplicationsState, RootState> = {
  state,
  mutations,
  actions,
};
