import { event as hmacValidatorEvent } from "./enums/hmac-validator";
import { event as applicationEvent } from "./enums/applications";
// prettier-ignore
const mapping: [string, string][] = [
  [hmacValidatorEvent.VisitHmacValidator, "HMAC validator - Visit"], 
  [applicationEvent.SubmitCreateApplication, "Application - Submit Create"],
  [applicationEvent.UpdateApplication, "Application - Update"],
  [applicationEvent.RegenerateSecretApplication, "Application - Regenerate the client's secret key"],
  [applicationEvent.DeleteApplication, "Application - Delete"],
];
/**
 * Will create event name for Mixpanel based on event enum.
 * @param {string} eventEnum Event enum listed, e.g., `PageOpened`
 * @returns {string | undefined} Mixpanel event name in string
 */
export const eventMapping = (eventEnum: string): string | undefined => {
  const map = new Map<string, string>();
  mapping.forEach((mapData) => map.set(mapData[0], mapData[1]));
  return map.get(eventEnum);
};