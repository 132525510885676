/**
 * Store types for user module.
 */
export const USER_SET_ID = "USER_SET_ID";
export const USER_SET_FIRST_NAME = "USER_SET_FIRST_NAME";
export const USER_SET_LAST_NAME = "USER_SET_LAST_NAME";
export const USER_SET_EMAIL = "USER_SET_EMAIL";
export const USER_SET_AVATAR = "USER_SET_AVATAR";
export const USER_CLEAR_DATA = "USER_CLEAR_DATA";
export const FETCH_CURRENT_USER = "FETCH_CURRENT_USER";
export const SHOW_TOAST = "SHOW_TOAST";

/**
 * Store types for applications module.
 */
export const APPLICATIONS_SET_HMAC = "APPLICATIONS_SET_HMAC";
export const FETCH_APPLICATIONS = "FETCH_APPLICATIONS";

/**
 * Store types for application module.
 */
export const APPLICATION_SET_HMAC_AUTH = "APPLICATION_SET_HMAC_AUTH";
export const APPLICATION_CREATE_HMAC_AUTH = "APPLICATION_CREATE_HMAC_AUTH";
export const APPLICATION_FETCH_HMAC_AUTH = "APPLICATION_FETCH_HMAC_AUTH";
export const APPLICATION_UPDATE_HMAC_AUTH = "APPLICATION_UPDATE_HMAC_AUTH";
export const APPLICATION_SET_NAME = "APPLICATION_SET_NAME";
export const APPLICATION_DELETE_HMAC_AUTH = "APPLICATION_DELETE_HMAC_AUTH";
export const APPLICATION_SET_CLIENT_ID = "APPLICATION_SET_CLIENT_ID";
export const APPLICATION_SET_CLIENT_SECRET = "APPLICATION_SET_CLIENT_SECRET";
export const APPLICATION_REGENRATE_SECRET_HMAC_AUTH =
  "APPLICATION_REGENRATE_SECRET_HMAC_AUTH";

/**
 * Store types for application module.
 */
export const COMPANY_SET_COMPANIES = "COMPANY_SET_COMPANIES";
export const COMPANY_FETCH_COMPANIES = "COMPANY_FETCH_COMPANIES";

/**
 * Store types for scope module.
 */
export const SCOPE_SET_SCOPES = "SCOPE_SET_SCOPES";
export const SCOPE_FETCH_SCOPES = "SCOPE_FETCH_SCOPES";

/**
 * Store types for access grant module.
 */
export const ACCESS_GRANT_SET_ACCESS_GRANTS = "ACCESS_GRANT_SET_ACCESS_GRANTS";
export const ACCESS_GRANT_FETCH_ACCESS_GRANTS =
  "ACCESS_GRANT_FETCH_ACCESS_GRANTS";
