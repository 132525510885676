/**
 * Popup vuex module
 */
import { Module, ActionTree, MutationTree } from "vuex";
import { RootState } from "@/interfaces";
import api from "@/internal-api";
import {
  ACCESS_GRANT_FETCH_ACCESS_GRANTS,
  ACCESS_GRANT_SET_ACCESS_GRANTS,
} from "@/store-types";
import { AccessGrantState, AccessGrant } from "@/interfaces/access_grant";

/**
 * Initial state
 */
const state: AccessGrantState = {};

/**
 * Module mutation
 */
const mutations: MutationTree<AccessGrantState> = {
  [ACCESS_GRANT_SET_ACCESS_GRANTS](
    state: AccessGrantState,
    accessGrant: AccessGrant
  ) {
    state.accessGrant = accessGrant;
  },
};

/**
 * Module action
 */
const actions: ActionTree<AccessGrantState, RootState> = {
  async [ACCESS_GRANT_FETCH_ACCESS_GRANTS]({ commit }, uuid: string) {
    const { result, error } = await api.accessGrants.getAccessGrant(uuid);

    if (error) {
      throw error;
    }
    commit(ACCESS_GRANT_SET_ACCESS_GRANTS, {
      id: result.data[0].id,
      requestorApp: result.data[0].requestor_application.name,
      products: result.data[0].products,
      scopes: result.data[0].scopes,
      scope_details: result.data[0].scope_details,
      email: result.data[0].email_owner,
      status: result.data[0].status,
    });
  },
};

export const access_grant: Module<AccessGrantState, RootState> = {
  state,
  mutations,
  actions,
};
