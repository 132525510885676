import store from "@/store";
import { FETCH_CURRENT_USER } from "@/store-types";

/**
 * Generates user properties for tracking purposes.
 *
 * @return {Object} User properties object
 */
export const userProperties = async () => {
  await store.dispatch(FETCH_CURRENT_USER);

  const companyId = store.state.application.hmacAuthApplication?.companyId;
  const company = store.state.company.companies.find(comp => comp.id === companyId);
  const companyName = company ? company.name : "-";
  const companySize = company ? company.companySize : "-";
  const platforms = company ? company.platforms.join() : "-";

  return {
    "Distinct ID": companyId || "-",
    "SSO Company ID": companyId || "-",
    "Company Name": companyName || "-",
    "Mekari User ID": store.state.user?.id || "-",
    "Mekari Email": store.state.user?.email || "-",
    "Is Using Product": platforms || "-",
    "Is New Trackers": true,
    "Company Size": companySize || "-"
  };
};
