import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { createI18n } from "vue-i18n";
import vueClickOutside from "@/directives/vue-click-outside";
import tooltip from "@/directives/tooltip";
import Toast from "vue-toastification";

import "vue-toastification/dist/index.css";
import "@/events/mixpanel/listen";

const i18n = createI18n({
  locale: "xx",
  fallbackLocale: "xx",
  messages: window.MDC_PAGEMETA.locale_messages,
});

createApp(App)
  .use(store)
  .use(router)
  .use(i18n)
  .use(Toast, {
    transition: "Vue-Toastification__fade",
    maxToasts: 1,
    newestOnTop: true,
    timeout: 3000,
  })
  .directive("click-outside", vueClickOutside)
  .directive("tooltip", tooltip)
  .mount("#app");
