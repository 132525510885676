import { ROUTES } from "@/constants/routes";
import NProgress from "nprogress";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { FETCH_CURRENT_USER } from "@/store-types";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "LandingPage",
    component: () =>
      import(/* webpackChunkName: "landing-page" */ "../views/LandingPage.vue"),
    meta: {
      title: "Mekari Developer",
    },
  },
  // {
  //   path: "/users/sign_up",
  //   name: "SignUpPage",
  //   component: () =>
  //     import(/* webpackChunkName: "signup" */ "../views/users/SignUp.vue"),
  //   meta: {
  //     title: "Sign Up - Mekari Developer",
  //   },
  // },
  {
    path: "/dashboard",
    redirect: { name: "ApplicationIndex" },
    name: "Dashboard",
    component: () =>
      import(
        /* webpackChunkName: "dashboard" */ "../views/dashboard/Index.vue"
      ),
    meta: {
      title: "Dashboard - Mekari Developer",
      requiresAuth: true,
    },
    children: [
      {
        path: "applications",
        name: "ApplicationIndex",
        component: () =>
          import(
            /* webpackChunkName: "application-index" */ "../views/dashboard/applications/Index.vue"
          ),
        meta: {
          title: "Applications - Mekari Developer",
          requiresAuth: true,
        },
      },
      {
        path: "applications/:uuid",
        name: "ApplicationShow",
        redirect: { name: "ApplicationShowDetail" },
        component: () =>
          import(
            /* webpackChunkName: "application-show" */ "../views/dashboard/applications/Show.vue"
          ),
        props: true,
        meta: {
          title: "Application - Mekari Developer",
          requiresAuth: true,
        },
        children: [
          {
            path: "detail",
            name: "ApplicationShowDetail",
            component: () =>
              import(
                /* webpackChunkName: "application-show-detail" */ "../views/dashboard/applications/show/Detail.vue"
              ),
            meta: {
              title: "Application - Mekari Developer",
              requiresAuth: true,
            },
          },
          {
            path: "credentials",
            name: "ApplicationShowCredentials",
            component: () =>
              import(
                /* webpackChunkName: "application-show-credentials" */ "../views/dashboard/applications/show/Credentials.vue"
              ),
            meta: {
              title: "Application - Mekari Developer",
              requiresAuth: true,
            },
          },
          {
            path: "advanced",
            name: "ApplicationShowAdvanced",
            component: () =>
              import(
                /* webpackChunkName: "application-show-advanced" */ "../views/dashboard/applications/show/Advanced.vue"
              ),
            meta: {
              title: "Application - Mekari Developer",
              requiresAuth: true,
            },
          },
        ],
      },
      {
        path: "create_application/hmac_auth",
        name: "CreateHmacAuthApplication",
        component: () =>
          import(
            /* webpackChunkName: "application-create" */ "../views/dashboard/applications/CreateHmacAuthApplication.vue"
          ),
        props: true,
        meta: {
          title: "Create HMAC Application - Mekari Developer",
          requiresAuth: true,
        },
      },
      {
        path: ROUTES.HMAC_VALIDATOR,
        name: "HmacValidator",
        component: () =>
          import(
            /* webpackChunkName: "hmac-validator" */ "../views/dashboard/applications/HmacValidator.vue"
          ),
        props: true,
        meta: {
          title: "HMAC Validator - Mekari Developer",
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: "/access_grant/approved",
    name: "AccessGrantApproved",
    component: () =>
      import(
        /* webpackChunkName: "404" */ "../views/access_grant/Approved.vue"
      ),
    props: true,
    meta: {
      title: "Grant Permission Approved - Mekari Developer",
      requiresAuth: true,
    },
  },
  {
    path: "/access_grant/:uuid",
    name: "AccessGrantPermission",
    component: () =>
      import(
        /* webpackChunkName: "404" */ "../views/access_grant/Permission.vue"
      ),
    props: true,
    meta: {
      title: "Grant Permission - Mekari Developer",
      requiresAuth: true,
    },
  },
  {
    path: "/forbidden",
    name: "403",
    component: () => import(/* webpackChunkName: "403" */ "../views/errors/Forbidden.vue"),
    meta: {
      title: "Forbidden - Mekari Developer",
    },
  },
  {
    path: "/internal_server_error",
    name: "5xx",
    component: () => import(/* webpackChunkName: "5xx" */ "../views/errors/ServerError.vue"),
    meta: {
      title: "Internal Server Error - Mekari Developer",
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "404",
    component: () => import(/* webpackChunkName: "404" */ "../views/errors/NotFound.vue"),
    meta: {
      title: "Page Not Found - Mekari Developer",
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.afterEach(async (to) => {
  window.document.title = (to.meta.title || "Mekari Developer") as string;

  if (to.meta.requiresAuth) {
    NProgress.start();

    await store.dispatch(FETCH_CURRENT_USER);

    if (store.state.user.id == "") {
      window.location.href = "/users/sign_in";
      return;
    }

    NProgress.done();
  }
});

export default router;
