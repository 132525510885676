const PREFIX = "hmac-validator";
/**
 * Event enum related to paywall
 * @enum {EventEnum}
 */
export const event = {
  VisitHmacValidator: `${PREFIX}.visit-hmac-validator`,
};
export const evenType = {
  EventHmacValidator: "HMAC Validator",
};