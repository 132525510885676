import { createPopper, Placement } from "@popperjs/core";

export default {
  mounted(el: any, binding: any) { // eslint-disable-line
    const { modifiers } = binding;

    // Create tooltip, and the append it to document.body
    const tooltipTemplate = document.createElement("div");
    tooltipTemplate.classList.add("mpx-tooltip", "mpx-fade");
    tooltipTemplate.innerHTML = `
      <div class="mpx-tooltip-inner">
        ${binding.value ? binding.value : ""}
      </div>
    `;
    document.body.appendChild(tooltipTemplate);

    // Helper function to get tooltip js placement on popperjs
    function getPosition(): Placement {
      let output = "top";
      const positionList = [
        "top-start",
        "top",
        "top-end",
        "right-start",
        "right",
        "right-end",
        "bottom-start",
        "bottom",
        "bottom-end",
        "left-start",
        "left",
        "left-end",
      ];
      positionList.forEach((position) => {
        output = modifiers[position] ? position : output;
      });
      return output as Placement;
    }

    // Function to handle click event
    el.handleClick = (e: Event) => {
      if (document.activeElement !== e.target) {
        tooltipTemplate.classList.toggle("mpx-show");
      }
    };

    // Function to handle show tooltip on hover and focus event
    el.showTooltip = () => {
      if (!el.popperInstance) {
        return;
      }

      el.popperInstance
        .update()
        .then(() => tooltipTemplate.classList.add("mpx-show"))
        .catch((e: string) => console.error(e)); // eslint-disable-line;
    };

    // Function to handle hide tooltip on hover and focus event
    el.hideTooltip = (e: Event) => {
      if (document.activeElement !== e.target) {
        tooltipTemplate.classList.remove("mpx-show");
      }
    };

    // Register popper to determine the position of tooltip
    el.popperInstance = createPopper(el, tooltipTemplate, {
      placement: getPosition(),
      modifiers: [
        {
          name: "offset",
          options: {
            offset: [0, 8],
          },
        },
      ],
    });

    // Handle event of tooltip
    if (modifiers.click) {
      el.addEventListener("click", el.handleClick);
    } else {
      el.addEventListener("mouseenter", el.showTooltip);
      el.addEventListener("mouseleave", el.hideTooltip);
    }
    el.addEventListener("focus", el.showTooltip);
    el.addEventListener("blur", el.hideTooltip);
  },

  unmounted(el: any) { // eslint-disable-line
    el.removeEventListener("click", el.handleClick);
    el.removeEventListener("mouseenter", el.showTooltip);
    el.removeEventListener("mouseleave", el.hideTooltip);
    el.removeEventListener("focus", el.showTooltip);
    el.removeEventListener("blur", el.hideTooltip);
  },
};
