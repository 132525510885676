/**
 * Popup vuex module
 */
import { Module, ActionTree, MutationTree } from "vuex";
import {
  APIResponse,
  CurrentUserAPIResponse,
  RootState,
  UserState,
} from "@/interfaces";
import api from "@/api";
import {
  USER_SET_ID,
  USER_SET_FIRST_NAME,
  USER_SET_LAST_NAME,
  USER_SET_EMAIL,
  USER_SET_AVATAR,
  USER_CLEAR_DATA,
  FETCH_CURRENT_USER,
} from "@/store-types";

/**
 * Module state
 */
const state: UserState = {
  id: "",
  firstName: "",
  lastName: "",
  email: "",
  avatar: "",
};

/**
 * Module mutation
 */
const mutations: MutationTree<UserState> = {
  [USER_SET_ID](state: UserState, id: string) {
    state.id = id;
  },
  [USER_SET_FIRST_NAME](state: UserState, firstName: string) {
    state.firstName = firstName;
  },
  [USER_SET_LAST_NAME](state: UserState, lastName: string) {
    state.lastName = lastName;
  },
  [USER_SET_EMAIL](state: UserState, email: string) {
    state.email = email;
  },
  [USER_SET_AVATAR](state: UserState, avatar: string) {
    state.avatar = avatar;
  },
  [USER_CLEAR_DATA](state: UserState) {
    state.id = "";
    state.firstName = "";
    state.lastName = "";
    state.email = "";
    state.avatar = "";
  },
};

/**
 * Module action
 */
const actions: ActionTree<UserState, RootState> = {
  /**
   * Fetch current user data
   */
  async [FETCH_CURRENT_USER]({ commit }) {
    const { result, error }: CurrentUserAPIResponse =
      (await api.users.me()) as APIResponse;

    if (error) {
      commit(USER_CLEAR_DATA);
      return;
    }

    const attributes = result.data.attributes;

    commit(USER_SET_ID, result.data.id);
    commit(USER_SET_FIRST_NAME, attributes.first_name);
    commit(USER_SET_LAST_NAME, attributes.last_name);
    commit(USER_SET_EMAIL, attributes.email);

    if (attributes.avatar_original && attributes.avatar_thumbnails.length > 0) {
      commit(USER_SET_AVATAR, attributes.avatar_thumbnails[0].url);
    } else {
      commit(USER_SET_AVATAR, attributes.avatar);
    }
  },
};

export const user: Module<UserState, RootState> = {
  state,
  mutations,
  actions,
};
