/**
 * Popup vuex module
 */
import { Module, ActionTree, MutationTree } from "vuex";
import { RootState } from "@/interfaces";
import api from "@/internal-api";
import { SCOPE_SET_SCOPES, SCOPE_FETCH_SCOPES } from "@/store-types";
import { GetScopesRequest, Scope, ScopeState } from "@/interfaces/scope";

/**
 * Initial state
 */
const state: ScopeState = {
  scopes: [],
};

/**
 * Module mutation
 */
const mutations: MutationTree<ScopeState> = {
  [SCOPE_SET_SCOPES](state: ScopeState, scopes: Array<Scope>) {
    state.scopes = scopes;
  },
};

/**
 * Module action
 */
const actions: ActionTree<ScopeState, RootState> = {
  async [SCOPE_FETCH_SCOPES]({ commit }, request: GetScopesRequest) {
    const getScopes = async (page = 1): Promise<Array<Scope>> => {
      const { result, error } = await api.scopes.getScopes({
        page,
        company_id: request.companyId,
        auth_type: request.authType,
      });

      if (error) {
        console.log("Got error on getScopes", error.message);
        return [];
      }

      if (result.data.length === 0) {
        return [];
      }

      const scopes: Array<Scope> = result.data.map((payload: any) => ({
        id: payload.id,
        name: payload.attributes.name,
        parentId: payload.attributes.parent_id,
      }));

      return scopes.concat(await getScopes(page + 1));
    };

    commit(SCOPE_SET_SCOPES, await getScopes());
  },
};

export const scope: Module<ScopeState, RootState> = {
  state,
  mutations,
  actions,
};
